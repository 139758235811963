.AuthContainerHolder {
    display: flex;
    justify-content: center;
}

.AuthContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding: 100px 0;
}

.AuthContainer > h1 {
    font-weight: 900;
    font-size: 20px;
    line-height: 25px;
}

.FormBlock {
    width: 100%;
    max-width: 360px;
    background: #F9F9F9;
    border-radius: 15px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    gap: 20px;
}

.FormBlock > h2 {
    margin: 0 0 20px 0;
}

.FormBlock button {
    width: 100%;
    margin: 0;
}

.CorpTitle {
    color: rgba(0, 0, 0, 0.28);
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
}

.FormBlock > * {
    z-index: 1;
}

.FormBlock > p {
    color: #8F8F8F;
    margin: 0 0 20px 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
}

.AuthBackground {
    position: absolute;
    top: 0;
    z-index: 0;
    height: 100%;
}