
.BackgroundLayer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-image: url(background-wave.svg);
}

.WelcomeText {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
    width: 100%;
    padding: 100px 0;
}

.WelcomeText > p {
    max-width: 600px;
}

.WelcomeText > h1 {
    font-weight: 900;
    font-size: 40px;
    line-height: 51px;
}

.ContractBlock {
    margin-left: -60px;
    padding: 60px;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 0px 171px rgba(0, 0, 0, 0.17);
    width: 75%;
    max-width: 670px;
    margin-bottom: 80px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 30px;
}

.ContractBlock > h2 {
    margin: 0;
}

.ContractBlock > p {
    margin: 0;
}

.ContractBlock a {
    font-weight: 900;
    font-size: 14px;
    text-decoration: none;
    color: black;
}

.ContractBlock ul {
    display: flex;
    flex-direction: row;
    justify-content: left;
    position: relative;
    margin: 0;
    padding: 0;
    gap: 40px;
}

.ContractBlock ul > li {
    list-style: none;
}


.RedTokenBlock {
    background: #FD414D;
    color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px 0;
    position: relative;
    overflow: hidden;
}

.RedTokenBlock > div {
    z-index: 1;
}

.RedTokenBlock > div > p {
    margin: 5px 0;
    max-width: 600px;
}

.RedTokenBlock > div > a {
    font-weight: 900;
    font-size: 14px;
    text-decoration: none;
    color: rgba(255, 255, 255, 0.71);
    margin-top: 200px;
}

.RedBackground {
    position: absolute;
    top: 0;
    z-index: 0;
}
