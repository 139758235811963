.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 140px;
}

.Header h1 {
  font-size: 18px;
  font-weight: 7  00;
}

.Header a {
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  color: black;
  cursor: pointer;
}

.Header ul {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 0;
  gap: 100px;
}

.Header ul > li {
  list-style: none;
}

.TopNotify {
  background: #5a20ff;
  color: white;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.TopNotify > div {
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.TopNotify p {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
}

.TopNotify img {
  height: 14px;
}

.Footer {
  background: black;
  color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 0;
}

.Footer p, .Footer a {
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  color: rgba(255, 255, 255, 0.7);
}

.Footer a {
  color: white;
  text-decoration: none;
}