.Container {
    width: 100%;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    padding: 0 50px;
    box-sizing: border-box;
}


.Button, .ButtonLocked {
    background: black;
    border-radius: 10px;
    border: none;
    color: white;
    font-size: 16px;
    font-weight: 600;
    font-family: var(--font-family);
    height: 50px;
    padding: 0 30px;
    margin: 15px 0;
    transition: all 0.1s ease-out;
}

.ButtonLocked {
    color: #8F8F8F;
    background: #EDEDED;
    cursor: not-allowed;
}

.Button:hover {
    transform: scale(1.05);
    cursor: pointer;
}

@media screen and (min-width: 1600px) {
    .Container {
        max-width: 1200px;
    }
}